import React from 'react';
import './Hero.scss';

const Hero = ({ data }) => {
  if (!data) return null;

  const { page_name, headline, background_image, text_block_1, text_block_2 } = data;

  return (
    <section className="hero">
      <div className="container">
        <div className="main-hero-content">
          <div className="hero-title-container"
          >
            <h1 className="hero-title" dangerouslySetInnerHTML={{__html: page_name}}></h1>
          </div>
          <div className="hero-headline-container">
            <div className="headline-dec">
              <div className="dec-circle"></div>
              <div className="dec-line"></div>
            </div>
            <h2 className="hero-headline" dangerouslySetInnerHTML={{__html: headline}}></h2>
          </div>
        </div>
        <div className="additional-hero-content">
          <div className="text-block-1">
            {text_block_1?.block_image?.url &&
              <div className="block-image">
                <img src={text_block_1.block_image.url} />
              </div>
            }
            <p dangerouslySetInnerHTML={{__html: text_block_1.body_copy}}></p>
          </div>
          <div className="text-block-2">
            {text_block_2?.block_image?.url &&
              // <div className="block-image">
              //   <img src={text_block_2.block_image.url} />
              // </div>
              <img className="block-image" src={text_block_2.block_image.url} />
            }
            <p dangerouslySetInnerHTML={{__html: text_block_2.body_copy}}></p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;

import React from 'react';
import { useEffect, useState } from 'react'
import './Services.scss'

import accordionArrow from './../../assets/accordion-arrow.png'

const Services = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const { headline, services_list } = data;


  useEffect(() => {
    console.log(services_list);
  }, [services_list]);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      handleAccordionClick(index);
    }
  };

  if (!data) return null;


  return (
    <section className="services">
        {headline && <h2 className="services-title" dangerouslySetInnerHTML={{ __html: headline }}></h2>}
        <div className="service-list-container">
          {services_list.map((item, index) => (
            <div
              key={index}
              className={`service-list-item ${activeIndex === index ? 'active' : ''}`}
              onClick={() => handleAccordionClick(index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              role="button"
              tabIndex={0}
              aria-expanded={activeIndex === index}
              aria-controls={`service-content-${index}`}
            >
              <div className="container">
                <div className="service-name-container">
                <h3 className="service-name">{item.service_name}</h3>
                <p className="service-description" dangerouslySetInnerHTML={{ __html: item.service_description }}></p>
                <img className="accordion-arrow" src={accordionArrow} alt="accordion arrow" />
                </div>
              <div className="service-details">
                <ul className="sub-services-listing">
                  {item.sub_service_listing && item.sub_service_listing.length > 0 && item.sub_service_listing.map((subService, subIndex) => (
                    <li key={subIndex} className="sub-service-item"  dangerouslySetInnerHTML={{ __html: subService.sub_service }}>
                    </li>
                  ))}
                </ul>
                <div className="service-details-content">
                  <div className="details">
                    <p dangerouslySetInnerHTML={{ __html: item.service_details }}></p>
                  </div>
                  {/* <div className="interactive-projects">
                    <div className="listing">
                      <h4>Interactive Projects</h4>
                      <ul className="project-listing">
                        {item.example_projects && item.example_projects.length > 0 && item.example_projects.map((project, projectIndex) => (
                          <li key={projectIndex} className="project-item">
                            <a className="project-name" dangerouslySetInnerHTML={{ __html: project.project_name }} href={project.project_link} target="_blank"></a>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="featured-project-image">
                      <img src={item.featured_project_image.url} alt="featured project" />
                    </div>
                  </div> */}
                </div>
                </div>
              </div>
            </div>
          ))}
        </div>
    </section>
  );
};

export default Services;

import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './WorkShowcase.scss';

import accordionArrow from './../../assets/accordion-arrow.png'

const WorkShowcase = ({ data }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

  // Check if device is mobile/tablet or viewport is tablet size
  useEffect(() => {
    const checkDevice = () => {
      const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      const isTabletViewport = window.innerWidth <= 1024;
      setIsMobileOrTablet(isMobileDevice || isTabletViewport);
    };

    checkDevice();
    window.addEventListener('resize', checkDevice);
    return () => window.removeEventListener('resize', checkDevice);
  }, []);

  if (!data) return null;

  const { headline, work_showcase_items } = data;

  const handleCarouselChange = (index) => {
    setActiveItemIndex(index);
  };

  const handleTabClick = (index) => {
    setActiveItemIndex(index);
  };

  return (
    <section className={`work-showcase ${isMobileOrTablet ? 'mobile' : ''}`}>
      <div className="container">
        <h2 className="work-showcase-title" dangerouslySetInnerHTML={{ __html: headline }}></h2>
        <div className="tabs">
          {work_showcase_items.map((item, index) => (
            <button
              key={index}
              className={`tab ${index === activeItemIndex ? 'active' : ''}`}
              onClick={() => handleTabClick(index)}
              aria-label={`Show ${item.showcase_headline}`}
              tabIndex={0}
            >
              <p className="work-showcase-title" dangerouslySetInnerHTML={{__html: item.showcase_headline}}></p>
            </button>
          ))}
        </div>
      </div>
      <div className="container">
        <Carousel
          selectedItem={activeItemIndex}
          onChange={handleCarouselChange}
          showArrows={true}
          showStatus={false}
          showThumbs={false}
          infiniteLoop={true}
          className={`work-showcase-carousel ${isMobileOrTablet ? 'mobile' : ''}`}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="carousel-button prev"
                aria-label="Previous quote"
              >
                <img src={accordionArrow} alt="Previous" style={{ transform: 'rotate(90deg)' }} />
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="carousel-button next"
                aria-label="Next quote"
              >
                <img src={accordionArrow} alt="Next" style={{ transform: 'rotate(-90deg)' }} />
              </button>
            )
          }
        >
          {work_showcase_items.map((item, index) => (
            <div
              key={index}
              className={`work-showcase-item ${index === activeItemIndex ? 'active' : ''}`}
            >
              <div className="work-showcase-content">
                <h3 className="work-showcase-title" dangerouslySetInnerHTML={{__html: item.showcase_headline}}></h3>
                <div className="work-showcase-description" dangerouslySetInnerHTML={{__html: item.showcase_body_copy}}></div>
              </div>
              <div className="work-showcase-image">
                <img src={item.showcase_image_desktop.url} alt={item.showcase_image_desktop.alt} className="work-showcase-image desktop"/>
                <img src={item.showcase_image_mobile.url} alt={item.showcase_image_mobile.alt} className="work-showcase-image mobile"/>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default WorkShowcase;

/*
@import '/src/styles/variables.scss';

.work-showcase {
  padding: 50px 0;
  min-height: 100vh;

  .carousel-root {
    .carousel {
      .control-arrow {
        opacity: 1;
        background: none;
        &:hover {
          background: none;
        }
        &:before {
          border: none;
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          border-top: 3px solid #000;
          border-right: 3px solid #000;
        }
        &.control-prev {
          &:before {
            transform: rotate(-135deg);
          }
        }
        &.control-next {
          &:before {
            transform: rotate(45deg);
          }
        }
      }
      .control-dots {
        .dot {
          background: #000;
          box-shadow: none;
          &.selected {
            background: #000;
          }
        }
      }
    }
  }

  h2 {
    font-family: 'ThePicnicClub', serif;
    font-style: normal;
    font-size: 80px;
    font-weight: 400;
    line-height: 84px;
    margin-bottom: 75px;
    @media (max-width: $breakpoint-tablet) {
      font-size: 60px;
      line-height: 65px;
      margin-bottom: 50px;
    }
    @media (max-width: $breakpoint-mobile) {
      font-size: 35px;
      line-height: 40px;
      margin-bottom: 20px;
    }
  }

  .tabs {
    display: flex;
    flex-direction: row;
    gap: 50px;
    margin-top: 50px;
    @media (max-width: $breakpoint-tablet) {
      display: none;
    }
    .tab {
      transition: 0.2s ease-in-out;
      padding: 5px 10px;
      border-radius: 12px;
      background-color: transparent;
      p {
        font-variation-settings: "wght" 400;
        font-weight: 400;
      }
      &.active {
        background-color: #e3e3e3;
        p {
          font-variation-settings: "wght" 700;
        }
      }
    }
  }

  .work-showcase-carousel {
    margin-top: 50px;
    .work-showcase-item {
      width: 669px;
      height: 308px;
      border-radius: 21px;
      background: #f4f4f4;
      display: flex;
      margin: 0 auto;
      @media (max-width: $breakpoint-tablet) {
        width: 100%;
        height: auto;
        min-height: 250px;
      }
      @media (max-width: $breakpoint-mobile) {
        min-height: 200px;
      }
    }
  }
}
*/

import React from 'react';
import { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './BrandExperience.scss';

import accordionArrow from './../../assets/accordion-arrow.png'

const BrandExperience = ({ data }) => {
  if (!data) return null;

  const { headline, body_copy, brand_display, quote_display } = data;

  return (
    <section className="brand-experience">
      <div className="container">
        <h2 className="brand-experience-title" dangerouslySetInnerHTML={{ __html: headline }}></h2>
        <p className="body-copy" dangerouslySetInnerHTML={{ __html: body_copy }}></p>
        <div className="brand-display-container">
          {brand_display.map((item, index) => (
            <div key={index} className="brand-display-item">
              <img src={item.brand_logo.url} alt={item.brand_name.alt} />
            </div>
          ))}
        </div>
        <div className="quote-display-container">
          <Carousel
            showThumbs={false}
            showStatus={false}
            infiniteLoop={true}
            autoPlay={true}
            interval={5000}
            transitionTime={500}
            swipeable={true}
            emulateTouch={true}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  className="carousel-button prev"
                  aria-label="Previous quote"
                >
                  <img src={accordionArrow} alt="Previous" style={{ transform: 'rotate(90deg)' }} />
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  className="carousel-button next"
                  aria-label="Next quote"
                >
                  <img src={accordionArrow} alt="Next" style={{ transform: 'rotate(-90deg)' }} />
                </button>
              )
            }
          >
            {quote_display.map((quote, index) => (
              <div key={index} className="quote-display-item">
                <div className='quote'>
                  <span>Words from our clients</span>
                  <p dangerouslySetInnerHTML={{ __html: quote.quote }}></p>
                </div>
                <p className="quote-attribution" dangerouslySetInnerHTML={{ __html: quote.quote_attribution }}></p>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default BrandExperience;

import React, { useEffect, useRef, useState } from 'react';
import './ClientOfferings.scss';

const ClientOfferings = ({ data }) => {
  const sectionRef = useRef(null);
  const carouselRef = useRef(null);
  const containerRef = useRef(null);
  const [isHorizontalScrolling, setIsHorizontalScrolling] = useState(false);
  const [hasScrolledToEnd, setHasScrolledToEnd] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(null);
  const [scrollDirection, setScrollDirection] = useState(null);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [isMobileView, setIsMobileView] = useState(false);

  // Check if device is mobile/tablet
  useEffect(() => {
    const checkMobileView = () => {
      setIsMobileView(window.innerWidth <= 1024 || /Mobi|Android|iPhone/i.test(navigator.userAgent));
    };

    checkMobileView();
    window.addEventListener('resize', checkMobileView);
    return () => window.removeEventListener('resize', checkMobileView);
  }, []);

  useEffect(() => {
    if (isMobileView) {
      const handleMobileScroll = () => {
        if (!carouselRef.current) return;

        const items = carouselRef.current.querySelectorAll('.client-offering-item');
        const viewportCenter = window.innerHeight / 2;

        items.forEach((item, index) => {
          const rect = item.getBoundingClientRect();
          const itemCenter = rect.top + (rect.height / 2);

          if (Math.abs(itemCenter - viewportCenter) < rect.height / 2) {
            setActiveItemIndex(index);
          }
        });
      };

      window.addEventListener('scroll', handleMobileScroll, { passive: false });
      return () => window.removeEventListener('scroll', handleMobileScroll);
    }

    const handleScroll = (e) => {
      const scrollY = window.scrollY;
      setLastScrollY(scrollY);
      getScrollDirection();

      if (!sectionRef.current) return;
      const rect = sectionRef.current.getBoundingClientRect();
      if (rect.top <= 100 && !hasScrolledToEnd && scrollDirection === 'down') {
        const scrollBack = rect.top - 100;
        window.scrollBy(0, scrollBack);
        document.body.style.overflowY = 'hidden';
        setIsHorizontalScrolling(true);
      }
      else if (rect.top >= 100 && hasScrolledToEnd && scrollDirection === 'up') {
        const scrollBack = rect.top - 100;
        window.scrollBy(0, scrollBack);
        document.body.style.overflowY = 'hidden';
        setIsHorizontalScrolling(true);
      }
      else if (isHorizontalScrolling) {
        return;
      }
      else {
        document.body.style.overflowY = 'auto';
        setIsHorizontalScrolling(false);
      }
    };

    const handleHorizontalScroll = (e) => {
      if (isHorizontalScrolling && carouselRef.current && containerRef.current) {
        e.preventDefault();
        const scrollAmount = e.deltaY;
        const currentTransform = carouselRef.current.style.transform;
        const currentX = currentTransform ? parseInt(currentTransform.match(/-?\d+/)[0]) : 0;

        const carouselWidth = carouselRef.current.scrollWidth;
        const containerWidth = containerRef.current.offsetWidth;
        const maxTranslation = -(carouselWidth - containerWidth);

        const newTranslation = Math.max(maxTranslation, Math.min(0, currentX - scrollAmount));
        carouselRef.current.style.transform = `translateX(${newTranslation}px)`;

        const items = carouselRef.current.querySelectorAll('.client-offering-item');
        const containerRect = containerRef.current.getBoundingClientRect();

        if (scrollAmount > 0) {
          setScrollDirection('down');
        }
        else if (scrollAmount < 0) {
          setScrollDirection('up');
        }

        items.forEach((item, index) => {
          const itemRect = item.getBoundingClientRect();

          if (scrollDirection === 'down') {
            if (itemRect.left <= containerRect.right && itemRect.right <= containerRect.right) {
              setActiveItemIndex(index);
            }
          } else if (scrollDirection === 'up') {
            if (itemRect.left >= containerRect.left && itemRect.right <= containerRect.right) {
              setActiveItemIndex(index);
            }
          }
        });

        if (newTranslation <= maxTranslation && scrollAmount > 0) {
          document.body.style.overflowY = 'auto';
          setIsHorizontalScrolling(false);
          setHasScrolledToEnd(true);
        } else if (newTranslation >= 0 && scrollAmount < 0) {
          document.body.style.overflowY = 'auto';
          setIsHorizontalScrolling(false);
          setHasScrolledToEnd(false);
        }
      }
    };

    // const handleWheel = (e) => {
    //   if (isHorizontalScrolling) {
    //     e.preventDefault();
    //     handleHorizontalScroll(e);
    //   }
    //   handleScroll();
    // };
    const handleWheel = (e) => {
      if (isHorizontalScrolling) {
        e.preventDefault(); // Stop the default scroll behavior only if horizontal scrolling is active
        handleHorizontalScroll(e);
      } else {
        handleScroll();
      }
    };

    const getScrollDirection = () => {
      if (lastScrollY > window.scrollY) {
        setScrollDirection('up');
      } else if (lastScrollY < window.scrollY) {
        setScrollDirection('down');
      }
    }

    if (!isMobileView) {
      window.addEventListener('scroll', handleScroll, { passive: false });
      window.addEventListener('wheel', handleWheel, { passive: false });
      window.addEventListener('touchmove', handleScroll, { passive: false });

      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('wheel', handleWheel);
        window.removeEventListener('touchmove', handleScroll);
      };
    }
  }, [isHorizontalScrolling, hasScrolledToEnd, lastScrollY, scrollDirection, isMobileView]);

  if (!data) return null;

  const { headline, client_offering_items } = data;

  return (
    <section className={`client-offerings ${isMobileView ? 'mobile-view' : ''}`} ref={sectionRef}>
      <div className="container" ref={containerRef}>
        <h2 className="client-offerings-title" dangerouslySetInnerHTML={{ __html: headline }}></h2>
        <div className="tabs">
        {client_offering_items.map((item, index) => (
          <button key={index} className={`tab ${index === activeItemIndex ? 'active' : ''}`}>
            <p className="client-offering-title" dangerouslySetInnerHTML={{__html: item.offering_headline}}></p>
          </button>
        ))}
        </div>
      </div>
      <div className="container client-offerings-carousel" ref={carouselRef}>
        {client_offering_items.map((item, index) => (
          <div
            key={index}
            className={`client-offering-item ${index === activeItemIndex ? 'active' : ''}`}
          >
            <div className="client-offering-content">
              <h3 className="client-offering-title" dangerouslySetInnerHTML={{__html: item.offering_headline}}></h3>
              <div className="client-offering-description" dangerouslySetInnerHTML={{__html: item.offering_body_copy}}></div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ClientOfferings;
